h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: 'Open Sans', sans-serif;
}

.react-reveal {
  overflow: auto;
}

.alice-carousel__dots {
  margin: 0px;
}

.alice-carousel__prev-btn {
  padding-bottom: 0px;
  padding-top: 0px;
}

.alice-carousel__next-btn {
  padding-bottom: 0px;
  padding-top: 0px;
  text-align: left;
}

.section {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
}

.programsCarousel {
  display: flex;
}

.programsCarousel .alice-carousel__stage {
  width: 220px;
}

.programsCarousel .alice-carousel__stage-item {
  width: 220px !important;
  margin-top: 20px;
  height: 250px;
}

@media screen and (max-width: 480px) and (min-width: 0px) {
  .programsCarousel .alice-carousel__wrapper,
  .programsCarousel .alice-carousel {
    width: 225px;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .programsCarousel .alice-carousel__wrapper,
  .programsCarousel .alice-carousel {
    width: 450px;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .programsCarousel .alice-carousel__wrapper,
  .programsCarousel .alice-carousel {
    width: 670px;
  }
}

@media screen and (max-width: 1279px) and (min-width: 992px) {
  .programsCarousel .alice-carousel__wrapper,
  .programsCarousel .alice-carousel {
    width: 670px;
  }
}

@media screen and (max-width: 1536px) and (min-width: 1279px) {
  .programsCarousel .alice-carousel__wrapper,
  .programsCarousel .alice-carousel {
    width: 670px;
  }
}

@media screen and (min-width: 1536px) {
  .programsCarousel .alice-carousel__wrapper,
  .programsCarousel .alice-carousel {
    width: 670px;
  }
}

.programsCarousel .alice-carousel__stage-item.__active {
  scale: 1.1;
  transition: scale 0.1s ease-in-out;
}

.programsCarousel .alice-carousel__stage-item.__active:hover {
  scale: 1.1;
  transition: scale 0;
}

.programsCarousel .alice-carousel__stage-item:hover {
  scale: 1.1;
  transition: scale 0.1s ease-in-out;
}

.mentorsCarousel {
  display: flex;
}

.mentorsCarousel .alice-carousel__stage {
  width: 190px;
  margin-left: 12px;
  margin-right: 12px;
}

.mentorsCarousel .alice-carousel__stage-item {
  width: 190px !important;
  margin-top: 20px;
  height: 215px;
}

@media screen and (max-width: 480px) and (min-width: 0px) {
  .mentorsCarousel .alice-carousel__wrapper,
  .mentorsCarousel .alice-carousel {
    width: 180px;
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .mentorsCarousel .alice-carousel__wrapper,
  .mentorsCarousel .alice-carousel {
    width: 370px;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .mentorsCarousel .alice-carousel__wrapper,
  .mentorsCarousel .alice-carousel {
    width: 560px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 992px) {
  .mentorsCarousel .alice-carousel__wrapper,
  .mentorsCarousel .alice-carousel {
    width: 370px;
  }
}

@media screen and (min-width: 1280px) {
  .mentorsCarousel .alice-carousel__wrapper,
  .mentorsCarousel .alice-carousel {
    width: 560px;
  }
}

.mentorsCarousel .alice-carousel__stage-item.__active {
  scale: 1.1;
  transition: scale 0.1s ease-in-out;
}

.mentorsCarousel .alice-carousel__stage-item.__active:hover {
  scale: 1.1;
  transition: scale 0;
}

.mentorsCarousel .alice-carousel__stage-item:hover {
  scale: 1.1;
  transition: scale 0.1s ease-in-out;
}

option {
  background-color: #0c3e5b !important;
}

.simpleCard:hover {
  scale: 1.1;
  transition: scale 0.1s ease-in-out;
}

.selectedCard {
  scale: 1.1;
  transition: scale 0.1s ease-in-out;
}

.chakra-select__icon-wrapper {
  color: #a0aec0 !important;
}
